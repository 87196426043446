import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Spinner,
  Button,
  Select,
  useColorModeValue,
  VStack,
  HStack,
  Text,
  Badge,
  IconButton,
  useBreakpointValue,
  SimpleGrid,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Code,
  useToast,
} from '@chakra-ui/react';
import * as xlsx from 'xlsx';
import { 
  MdFileDownload, 
  MdFilterList, 
  MdSearch, 
  MdEuro, 
  MdPerson, 
  MdCalendarToday,
  MdGroup,
  MdChevronLeft,
  MdChevronRight,
  MdFirstPage,
  MdLastPage,
  MdSync,
} from 'react-icons/md';
import { db } from "lib/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

// Fonctions utilitaires
const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const formatMontant = (montant) => {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  }).format(montant);
};

// Composant de ligne de tableau optimisé
const TableRow = React.memo(({ contribution, tableHoverBg, tableStripedBg, index, textColor }) => {
  return (
    <Tr 
      _hover={{ bg: tableHoverBg }}
      bg={index % 2 === 0 ? 'transparent' : tableStripedBg}
      cursor="pointer"
      transition="background-color 0.2s"
    >
      <Td py={4}>
        <Badge 
          colorScheme="purple" 
          fontSize="sm"
          px={2}
          py={1}
          borderRadius="md"
        >
          {contribution.adherentId || 'N/A'}
        </Badge>
      </Td>
      <Td py={4}>{contribution.mrName}</Td>
      <Td py={4}>{contribution.delegateName}</Td>
      <Td py={4}>
        <Badge 
          colorScheme="teal" 
          fontSize="sm"
          px={2}
          py={1}
          borderRadius="md"
        >
          {contribution.annee}
        </Badge>
      </Td>
      <Td py={4} fontWeight="medium" color="teal.500">
        {formatMontant(contribution.montant)}
      </Td>
      <Td py={4}>
        {formatDate(new Date(contribution.datePaiement))}
      </Td>
      <Td py={4}>
        {contribution.responsableNom && contribution.responsablePrenom ? (
          `${contribution.responsablePrenom} ${contribution.responsableNom}`
        ) : (
          <Badge 
            colorScheme="gray" 
            fontSize="sm"
            px={2}
            py={1}
            borderRadius="md"
          >
            Non spécifié
          </Badge>
        )}
      </Td>
    </Tr>
  );
});

// Composant de tableau optimisé
const ContributionsTable = React.memo(({ 
  paginatedData, 
  tableBg, 
  tableHeaderBg, 
  tableBorderColor, 
  tableHoverBg, 
  tableStripedBg,
  textColor 
}) => (
  <Box 
    overflowX="auto"
    bg={tableBg}
    borderRadius="lg"
    borderWidth="1px"
    borderColor={tableBorderColor}
    shadow="sm"
  >
    <Table variant="simple">
      <Thead bg={tableHeaderBg}>
        <Tr>
          {['N° Adhérent', 'Adhérent', 'Délégué', 'Année', 'Montant', 'Date de paiement', 'Responsable'].map((header) => (
            <Th 
              key={header}
              color={textColor}
              borderBottom={`2px solid ${tableBorderColor}`}
              py={4}
            >
              {header}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {paginatedData.map((contribution, index) => (
          <TableRow 
            key={contribution.id}
            contribution={contribution}
            tableHoverBg={tableHoverBg}
            tableStripedBg={tableStripedBg}
            index={index}
            textColor={textColor}
          />
        ))}
      </Tbody>
    </Table>
  </Box>
));

// Composant FilterSection optimisé
const FilterSection = React.memo(({ 
  searchQuery, 
  onSearchChange, 
  filterYear,
  filterMonth,
  filterDay,
  filterResponsable, 
  onFilterYearChange,
  onFilterMonthChange,
  onFilterDayChange,
  onFilterResponsableChange,
  onExportClick,
  cardBg,
  borderColor,
  iconColor,
  inputBg,
  uniqueDates,
  uniqueResponsables
}) => (
  <Stack
    direction={{ base: 'column', md: 'row' }}
    spacing={4}
    mb={6}
    align={{ base: 'stretch', md: 'center' }}
    bg={cardBg}
    p={4}
    borderRadius="lg"
    borderWidth="1px"
    borderColor={borderColor}
    shadow="sm"
    flexWrap="wrap"
  >
    <InputGroup maxW={{ base: "full", md: "300px" }}>
      <InputLeftElement pointerEvents="none">
        <Icon as={MdSearch} color={iconColor} />
      </InputLeftElement>
      <Input
        placeholder="Rechercher un adhérent ou délégué..."
        value={searchQuery}
        onChange={onSearchChange}
        bg={inputBg}
        borderColor={borderColor}
        _focus={{
          borderColor: 'teal.500',
          boxShadow: '0 0 0 1px var(--chakra-colors-teal-500)',
        }}
      />
    </InputGroup>

    <Select
      value={filterDay}
      onChange={onFilterDayChange}
      placeholder="Jour"
      bg={inputBg}
      borderColor={borderColor}
      maxW={{ base: "full", md: "120px" }}
      _focus={{
        borderColor: 'teal.500',
        boxShadow: '0 0 0 1px var(--chakra-colors-teal-500)',
      }}
    >
      <option value="">Tous</option>
      {uniqueDates.days.map(day => (
        <option key={day} value={day}>{day}</option>
      ))}
    </Select>

    <Select
      value={filterMonth}
      onChange={onFilterMonthChange}
      placeholder="Mois"
      bg={inputBg}
      borderColor={borderColor}
      maxW={{ base: "full", md: "120px" }}
      _focus={{
        borderColor: 'teal.500',
        boxShadow: '0 0 0 1px var(--chakra-colors-teal-500)',
      }}
    >
      <option value="">Tous</option>
      {uniqueDates.months.map(month => (
        <option key={month} value={month}>
          {new Date(2000, month - 1).toLocaleString('fr-FR', { month: 'long' })}
        </option>
      ))}
    </Select>

    <Select
      value={filterYear}
      onChange={onFilterYearChange}
      placeholder="Année"
      bg={inputBg}
      borderColor={borderColor}
      maxW={{ base: "full", md: "120px" }}
      _focus={{
        borderColor: 'teal.500',
        boxShadow: '0 0 0 1px var(--chakra-colors-teal-500)',
      }}
    >
      <option value="">Toutes</option>
      {uniqueDates.years.map(year => (
        <option key={year} value={year}>{year}</option>
      ))}
    </Select>

    <Select
      value={filterResponsable}
      onChange={onFilterResponsableChange}
      placeholder="Responsable"
      bg={inputBg}
      borderColor={borderColor}
      maxW={{ base: "full", md: "200px" }}
      _focus={{
        borderColor: 'teal.500',
        boxShadow: '0 0 0 1px var(--chakra-colors-teal-500)',
      }}
    >
      <option value="">Tous</option>
      {uniqueResponsables.map(responsable => (
        <option key={responsable} value={responsable}>{responsable}</option>
      ))}
    </Select>

    <Button
      leftIcon={<Icon as={MdFileDownload} boxSize={5} />}
      onClick={onExportClick}
      colorScheme="teal"
      variant="solid"
      size="md"
      width={{ base: "full", md: "auto" }}
      px={6}
      py={4}
      fontWeight="medium"
      shadow="sm"
      _hover={{
        transform: 'translateY(-1px)',
        shadow: 'md',
        bg: 'teal.600',
      }}
      _active={{
        transform: 'translateY(0)',
        shadow: 'sm',
      }}
      transition="all 0.2s"
    >
      Exporter Excel
    </Button>
  </Stack>
));

const ContributionTable = () => {
  const toast = useToast();
  const [contributions, setContributions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filterYear, setFilterYear] = useState('');
  const [filterMonth, setFilterMonth] = useState('');
  const [filterDay, setFilterDay] = useState('');
  const [filterResponsable, setFilterResponsable] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const PAGES_RANGE = 3;
  const [isSyncing, setIsSyncing] = useState(false);
  const [lastSyncResult, setLastSyncResult] = useState(null);
  const [responsablesCache, setResponsablesCache] = useState({});

  const isMobile = useBreakpointValue({ base: true, md: false });

  // Theme colors
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const mutedTextColor = useColorModeValue('gray.600', 'gray.400');
  const badgeBg = useColorModeValue('teal.50', 'teal.900');
  const badgeColor = useColorModeValue('teal.600', 'teal.200');
  const hoverBg = useColorModeValue('gray.50', 'gray.700');
  const iconColor = useColorModeValue('gray.600', 'gray.400');
  const tableBg = useColorModeValue('white', 'gray.800');
  const tableHeaderBg = useColorModeValue('gray.50', 'gray.700');
  const tableBorderColor = useColorModeValue('gray.200', 'gray.600');
  const tableHoverBg = useColorModeValue('gray.50', 'gray.700');
  const tableStripedBg = useColorModeValue('gray.50', 'gray.700');
  const pageBg = useColorModeValue('gray.50', 'gray.900');
  const inputBg = useColorModeValue('white', 'gray.700');

  // Debounced search query
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');

  // Effet pour le debounce
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 300); // 300ms delay

    return () => clearTimeout(timer);
  }, [searchQuery]);

  const paginateData = (data, currentPage, itemsPerPage) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const exportToExcel = () => {
    const workbook = xlsx.utils.book_new();
  
    const data = filteredContributions.map(contribution => ({
      'N° Adhérent': contribution.adherentId || 'N/A',
      'Adhérent': contribution.mrName,
      'Délégué': contribution.delegateName,
      'Année': contribution.annee,
      'Montant': contribution.montant,
      'Date de paiement': contribution.datePaiement ? formatDate(new Date(contribution.datePaiement)) : '',
      'Responsable': contribution.responsableNom && contribution.responsablePrenom 
        ? `${contribution.responsablePrenom} ${contribution.responsableNom}`
        : "Non spécifié"
    }));
  
    const worksheet = xlsx.utils.json_to_sheet(data);
    xlsx.utils.book_append_sheet(workbook, worksheet, 'Cotisations');
  
    const currentDate = new Date();
    const fileName = `cotisations_${formatDate(currentDate).replace(/\//g, '-')}.xlsx`;
  
    xlsx.writeFile(workbook, fileName);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(filteredContributions.length / itemsPerPage);
  
    if (totalPages <= 1) return null;

    return (
      <HStack spacing={2} justify="center" mt={6}>
        <IconButton
          icon={<MdFirstPage />}
          onClick={() => handlePageChange(1)}
          isDisabled={currentPage === 1}
          variant="ghost"
          aria-label="Première page"
        />
        <IconButton
          icon={<MdChevronLeft />}
          onClick={() => handlePageChange(currentPage - 1)}
          isDisabled={currentPage === 1}
          variant="ghost"
          aria-label="Page précédente"
        />
        
        <HStack spacing={2}>
          {[...Array(totalPages)].map((_, index) => {
            const pageNumber = index + 1;
            const isCurrentPage = pageNumber === currentPage;
            const isWithinRange = Math.abs(pageNumber - currentPage) <= PAGES_RANGE;
            const isFirstPage = pageNumber === 1;
            const isLastPage = pageNumber === totalPages;

            if (isWithinRange || isFirstPage || isLastPage) {
              return (
                <Button
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                  colorScheme={isCurrentPage ? 'teal' : 'gray'}
                  variant={isCurrentPage ? 'solid' : 'ghost'}
                  size="sm"
                >
                  {pageNumber}
                </Button>
              );
            } else if (
              (pageNumber === currentPage - PAGES_RANGE - 1) ||
              (pageNumber === currentPage + PAGES_RANGE + 1)
            ) {
              return <Text key={pageNumber}>...</Text>;
            }
            return null;
          })}
        </HStack>

        <IconButton
          icon={<MdChevronRight />}
          onClick={() => handlePageChange(currentPage + 1)}
          isDisabled={currentPage === totalPages}
          variant="ghost"
          aria-label="Page suivante"
        />
        <IconButton
          icon={<MdLastPage />}
          onClick={() => handlePageChange(totalPages)}
          isDisabled={currentPage === totalPages}
          variant="ghost"
          aria-label="Dernière page"
        />
      </HStack>
    );
  };

  useEffect(() => {
    const fetchContributions = async () => {
      try {
        const response = await fetch('https://app.falconmarketing.fr:3004/cotisations');
        const data = await response.json();
        setContributions(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des cotisations:", error);
        setIsLoading(false);
      }
    };

    fetchContributions();
  }, []);

  // Get unique dates from datePaiement
  const uniqueDates = React.useMemo(() => {
    const dates = new Set();
    const years = new Set();
    const months = new Set();
    const days = new Set();
    
    contributions.forEach(contribution => {
      if (contribution.datePaiement) {
        const date = new Date(contribution.datePaiement);
        years.add(date.getFullYear());
        months.add(date.getMonth() + 1);
        days.add(date.getDate());
      }
    });
    
    return {
      years: Array.from(years).sort((a, b) => b - a),
      months: Array.from(months).sort((a, b) => a - b),
      days: Array.from(days).sort((a, b) => a - b)
    };
  }, [contributions]);

  // Optimisation du filtrage
  const filteredContributions = React.useMemo(() => {
    if (!searchQuery && !filterYear && !filterMonth && !filterDay && !filterResponsable) return contributions;
    
    const searchLower = searchQuery.toLowerCase();
    return contributions.filter(contribution => {
      const date = new Date(contribution.datePaiement);
      const yearMatch = !filterYear || date.getFullYear() === parseInt(filterYear);
      const monthMatch = !filterMonth || (date.getMonth() + 1) === parseInt(filterMonth);
      const dayMatch = !filterDay || date.getDate() === parseInt(filterDay);
      const responsableMatch = !filterResponsable || 
        (contribution.responsableNom && contribution.responsablePrenom && 
         `${contribution.responsablePrenom} ${contribution.responsableNom}` === filterResponsable);
      const searchMatch = !searchQuery || 
        (contribution.mrName || '').toLowerCase().includes(searchLower) ||
        (contribution.delegateName || '').toLowerCase().includes(searchLower);
      return yearMatch && monthMatch && dayMatch && responsableMatch && searchMatch;
    });
  }, [contributions, filterYear, filterMonth, filterDay, filterResponsable, searchQuery]);

  // Calculer les données paginées avec useMemo
  const paginatedData = React.useMemo(() => {
    return paginateData(filteredContributions, currentPage, itemsPerPage);
  }, [filteredContributions, currentPage, itemsPerPage]);

  // Callbacks pour les handlers
  const handleSearchChange = useCallback((e) => {
    setSearchQuery(e.target.value);
  }, []);

  const handleFilterYearChange = useCallback((e) => {
    setFilterYear(e.target.value);
  }, []);

  const handleFilterMonthChange = useCallback((e) => {
    setFilterMonth(e.target.value);
  }, []);

  const handleFilterDayChange = useCallback((e) => {
    setFilterDay(e.target.value);
  }, []);

  const handleFilterResponsableChange = useCallback((e) => {
    setFilterResponsable(e.target.value);
  }, []);

  // Get unique responsables
  const uniqueResponsables = React.useMemo(() => {
    const responsables = new Set();
    contributions.forEach(contribution => {
      if (contribution.responsableNom && contribution.responsablePrenom) {
        responsables.add(`${contribution.responsablePrenom} ${contribution.responsableNom}`);
      }
    });
    return Array.from(responsables).sort();
  }, [contributions]);

  // Fonction pour tester la synchronisation avec un seul responsable
  const handleTestSync = async () => {
    setIsSyncing(true);
    try {
      // Trouver la première contribution sans responsable mais avec un responsableId
      const contributionToUpdate = contributions.find(c => 
        (!c.responsableNom || !c.responsablePrenom) && c.responsableId
      );

      if (!contributionToUpdate) {
        throw new Error('Aucune contribution sans responsable trouvée');
      }

      // Récupérer les données du responsable depuis Firebase
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("uid", "==", contributionToUpdate.responsableId));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        throw new Error('Responsable non trouvé dans Firebase');
      }

      // Récupérer les données du responsable
      const userData = querySnapshot.docs[0].data();
      const testResponsable = {
        uid: userData.uid,
        nom: userData.nom,
        prenom: userData.prenom,
        contributionId: contributionToUpdate.id
      };

      // Mettre à jour le cache des responsables
      setResponsablesCache(prev => ({
        ...prev,
        [testResponsable.uid]: testResponsable
      }));

      // Envoyer les données au backend pour mise à jour SQLite
      const response = await fetch('https://app.falconmarketing.fr:3004/sync-responsables', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          responsables: [testResponsable],
          contributionId: contributionToUpdate.id
        }),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la synchronisation');
      }

      // Rafraîchir les données
      const newContributions = await fetch('https://app.falconmarketing.fr:3004/cotisations');
      const contributionsData = await newContributions.json();
      setContributions(contributionsData);

      // Mettre à jour le résultat du test
      setLastSyncResult({
        ...testResponsable,
        contributionId: contributionToUpdate.id,
        adherentName: contributionToUpdate.mrName,
        success: true
      });

      toast({
        title: "Test de synchronisation réussi",
        description: `Responsable ${testResponsable.prenom} ${testResponsable.nom} synchronisé pour la contribution de ${contributionToUpdate.mrName}`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Erreur lors du test de synchronisation:", error);
      setLastSyncResult({
        error: error.message,
        success: false
      });
      toast({
        title: "Erreur de synchronisation",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSyncing(false);
    }
  };

  // Fonction pour synchroniser tous les responsables
  const handleSync = async () => {
    setIsSyncing(true);
    try {
      // Récupérer tous les responsables de Firebase
      const usersRef = collection(db, "users");
      const querySnapshot = await getDocs(usersRef);
      
      // Préparer les données des responsables
      const responsables = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          uid: data.uid,
          nom: data.nom,
          prenom: data.prenom
        };
      });

      // Mettre à jour le cache des responsables
      const newCache = {};
      responsables.forEach(resp => {
        newCache[resp.uid] = resp;
      });
      setResponsablesCache(newCache);

      // Envoyer les données au backend pour mise à jour SQLite
      const response = await fetch('https://app.falconmarketing.fr:3004/sync-responsables', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ responsables }),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la synchronisation');
      }

      // Rafraîchir les données
      const newContributions = await fetch('https://app.falconmarketing.fr:3004/cotisations');
      const data = await newContributions.json();
      setContributions(data);

      toast({
        title: "Synchronisation réussie",
        description: "Les responsables ont été mis à jour avec succès.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Erreur lors de la synchronisation:", error);
      toast({
        title: "Erreur de synchronisation",
        description: "Une erreur est survenue lors de la synchronisation des responsables.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSyncing(false);
    }
  };

  const ContributionCard = ({ contribution }) => (
    <Box
      bg={cardBg}
      borderColor={borderColor}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      _hover={{ transform: 'translateY(-2px)', shadow: 'md' }}
      transition="all 0.2s"
      p={4}
    >
      <VStack align="stretch" spacing={3}>
        <HStack justify="space-between">
          <Badge colorScheme="teal" fontSize="sm" px={2} py={1}>
            {contribution.annee}
          </Badge>
          <Text fontWeight="bold" color={textColor}>
            {formatMontant(contribution.montant)}
          </Text>
        </HStack>

        <VStack align="stretch" spacing={2}>
          <HStack>
            <Icon as={MdPerson} color={iconColor} />
            <Text color={textColor}>{contribution.mrName}</Text>
          </HStack>
          <HStack>
            <Icon as={MdGroup} color={iconColor} />
            <Text color={mutedTextColor}>{contribution.delegateName}</Text>
          </HStack>
          <HStack>
            <Icon as={MdCalendarToday} color={iconColor} />
            <Text color={mutedTextColor}>
              {formatDate(new Date(contribution.datePaiement))}
            </Text>
          </HStack>
        </VStack>

        {contribution.responsableNom && contribution.responsablePrenom && (
          <Text fontSize="sm" color={mutedTextColor}>
            Responsable: {contribution.responsablePrenom} {contribution.responsableNom}
          </Text>
        )}
      </VStack>
    </Box>
  );

  if (isLoading) {
    return (
      <Flex justify="center" align="center" h="200px">
        <Spinner size="xl" color="teal.500" />
      </Flex>
    );
  }

  return (
    <Box p={4} bg={pageBg} minH="100vh">
      <VStack spacing={6} align="stretch">
        <HStack 
          justify="space-between" 
          wrap="wrap" 
          spacing={4}
          bg={cardBg}
          p={4}
          borderRadius="lg"
          borderWidth="1px"
          borderColor={borderColor}
          shadow="sm"
        >
          <Heading size="lg" color={textColor} mb={{ base: 4, md: 0 }}>
            Cotisations
          </Heading>
          <Stack 
            direction={{ base: "column", md: "row" }} 
            spacing={4} 
            align="center"
          >
            {(searchQuery || filterYear || filterMonth || filterDay || filterResponsable) && (
              <Badge 
                colorScheme="green" 
                p={2} 
                borderRadius="md"
                fontSize="md"
              >
                Total : {formatMontant(filteredContributions.reduce((sum, contribution) => sum + contribution.montant, 0))}
              </Badge>
            )}
            <Badge 
              colorScheme="teal" 
              p={2} 
              borderRadius="md"
              fontSize="md"
            >
              {filteredContributions.length} résultat{filteredContributions.length > 1 ? 's' : ''}
            </Badge>
          </Stack>
        </HStack>

        <FilterSection
          searchQuery={searchQuery}
          onSearchChange={handleSearchChange}
          filterYear={filterYear}
          filterMonth={filterMonth}
          filterDay={filterDay}
          filterResponsable={filterResponsable}
          onFilterYearChange={handleFilterYearChange}
          onFilterMonthChange={handleFilterMonthChange}
          onFilterDayChange={handleFilterDayChange}
          onFilterResponsableChange={handleFilterResponsableChange}
          onExportClick={exportToExcel}
          cardBg={cardBg}
          borderColor={borderColor}
          iconColor={iconColor}
          inputBg={inputBg}
          uniqueDates={uniqueDates}
          uniqueResponsables={uniqueResponsables}
        />

        {paginatedData.length > 0 ? (
          <>
            {isMobile ? (
              <SimpleGrid columns={1} spacing={4}>
                {paginatedData.map((contribution) => (
                  <ContributionCard key={contribution.id} contribution={contribution} />
                ))}
              </SimpleGrid>
            ) : (
              <ContributionsTable 
                paginatedData={paginatedData}
                tableBg={tableBg}
                tableHeaderBg={tableHeaderBg}
                tableBorderColor={tableBorderColor}
                tableHoverBg={tableHoverBg}
                tableStripedBg={tableStripedBg}
                textColor={textColor}
              />
            )}
            {renderPagination()}
          </>
        ) : (
          <Flex 
            direction="column" 
            align="center" 
            justify="center" 
            py={10}
            bg={cardBg}
            borderRadius="lg"
            borderWidth="1px"
            borderColor={borderColor}
            shadow="sm"
          >
            <Icon 
              as={MdSearch} 
              boxSize={12} 
              color={mutedTextColor} 
              mb={4}
            />
            <Text fontSize="lg" color={mutedTextColor} textAlign="center" fontWeight="medium">
              Aucune cotisation trouvée
            </Text>
            <Text fontSize="sm" color={mutedTextColor} mt={2} textAlign="center">
              Essayez de modifier vos filtres de recherche
            </Text>
          </Flex>
        )}
      </VStack>
    </Box>
  );
};

export default ContributionTable;