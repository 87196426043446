import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// Base font size for scaling
const baseFontSize = 9.5;

// Stylesheet with variables for consistency
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    padding: 10,
  },
  heading: {
    fontSize: baseFontSize + 1,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginBottom: 4,
    textAlign: 'center',
  },
  label: {
    fontSize: baseFontSize,
    marginBottom: 1,
  },
  field: {
    fontSize: baseFontSize + 1,
    fontWeight: 'bold',
    borderBottomWidth: 1,
    borderBottomColor: '#000000',
    marginBottom: 2,
    paddingVertical: 1,
    minHeight: 16,
  },
  table: {
    marginTop: 8,
  },
  tableRow: {
    flexDirection: 'row',
    marginBottom: 2,
  },
  tableCell: {
    fontSize: baseFontSize,
    flex: 1,
    borderWidth: 1,
    borderColor: '#000000',
    padding: 2,
    textAlign: 'center',
  },
  checkBoxGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 4,
    justifyContent: 'space-between',
  },
  checkBoxItem: {
    width: '9%',
    alignItems: 'center',
    marginBottom: 3,
  },
  checkBoxLabel: {
    fontSize: baseFontSize - 2.5,
    marginBottom: 1,
  },
  checkBox: {
    width: 7,
    height: 7,
    borderWidth: 0.75,
    borderColor: '#000',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkedBox: {
    width: 4,
    height: 4,
    backgroundColor: '#000',
  },
  signature: {
    marginTop: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#000000',
    marginBottom: 10,
    width: '40%',
  },
  signatureContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 10,
    marginBottom: 10,
  },
  signatureLabel: {
    fontSize: baseFontSize,
    marginBottom: 5,
  },
  childrenTable: {
    marginTop: 10,
    marginBottom: 10,
  },
  childRow: {
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: '#000000',
    marginBottom: -1, // Pour éviter les doubles bordures
  },
  childNameCell: {
    flex: 2,
    padding: 8,
    borderRightWidth: 1,
    borderRightColor: '#000000',
  },
  childDateCell: {
    flex: 1,
    padding: 8,
  },
  childTableText: {
    fontSize: baseFontSize - 1, // Taille de police réduite pour le tableau des enfants
  },
  smallerText: {
    fontSize: baseFontSize - 1.5, // Taille de police encore plus réduite pour les textes d'engagement
    marginBottom: 1,
  },
  documentsTitle: {
    fontSize: baseFontSize,
    fontWeight: 'bold',
    marginTop: 8,
    marginBottom: 4,
    textDecoration: 'underline',
  },
  documentItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 2,
  },
  documentText: {
    fontSize: baseFontSize - 1,
    marginLeft: 5,
  },
});

const CheckBox = ({ checked }) => (
  <View style={styles.checkBox}>
    {checked && <View style={styles.checkedBox} />}
  </View>
);

function formatDate(dateString) {
  if (!dateString) return '';
  const date = new Date(dateString);
  if (isNaN(date)) return '';
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

const CheckBoxTable = ({ lastUpdatedYear }) => {
  const years = Array.from({ length: 20 }, (_, i) => 2024 + i);

  return (
    <View style={styles.checkBoxGrid}>
      {years.map((year) => (
        <View key={year} style={styles.checkBoxItem}>
          <Text style={styles.checkBoxLabel}>{year}</Text>
          <CheckBox checked={year <= lastUpdatedYear} />
        </View>
      ))}
    </View>
  );
};

const FamilyStatusTable = ({ maritalStatus }) => {
  const statuses = [
    { key: 'married', label: 'Marié' },
    { key: 'pacs', label: 'Pacsé' },
    { key: 'single', label: 'Célibataire' },
    { key: 'divorcedWithChildren', label: 'Divorcé avec enfants' },
    { key: 'divorcedWithoutChildren', label: 'Divorcé sans enfants' },
    { key: 'widowed', label: 'Veuve/Veuf' },
  ];

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 3, marginBottom: 3 }}>
      <Text style={[styles.label, { marginRight: 5, fontSize: baseFontSize - 1 }]}>SITUATION FAMILIALE :</Text>
      <View style={{ flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
        {statuses.map((status) => (
          <View key={status.key} style={{
            borderWidth: 1,
            borderColor: '#000000',
            padding: 2,
            width: '14%',
            alignItems: 'center'
          }}>
            <Text style={[styles.label, { fontSize: baseFontSize - 2, marginBottom: 1 }]}>{status.label}</Text>
            <CheckBox checked={maritalStatus === status.key} />
          </View>
        ))}
      </View>
    </View>
  );
};

const DocumentsList = () => {
  const documents = [
    "Copie de la carte d'identité ou du titre de séjour (recto-verso)",
    "Copie du livret de famille ou des extraits de naissance",
    "Attestation de Sécurité Sociale Amélie."
  ];

  return (
    <View style={{ marginTop: 10 }}>
      <Text style={styles.documentsTitle}>Documents à fournir pour chacune des personnes de la fiche :</Text>
      {documents.map((doc, index) => (
        <View key={index} style={styles.documentItem}>
          <CheckBox checked={false} />
          <Text style={styles.documentText}>{doc}</Text>
        </View>
      ))}
    </View>
  );
};

const AdherentPDF = ({ adherent, children }) => {
  const lastUpdatedYear = new Date(adherent.contributionUpdatedAt).getFullYear();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View>
          <Text style={styles.heading}>L'association El Mektoub</Text>
          <Text style={styles.heading}>Bulletin d'adhésion</Text>
        </View>

        {/* Personal Information */}
        <View style={{ marginTop: 10 }}>
          <Text style={styles.label}>NOM ET PRÉNOM</Text>
          <Text style={styles.field}>{adherent.mrName}</Text>

          <Text style={styles.label}>NOM ET PRÉNOM (CONJOINT)</Text>
          <Text style={styles.field}>{adherent.mrsName}</Text>

          <View style={styles.tableRow}>
            <View style={{ flex: 1, marginRight: 5 }}>
              <Text style={styles.label}>DATE DE NAISSANCE</Text>
              <Text style={styles.field}>{formatDate(adherent.birthDate)}</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={styles.label}>DATE DE NAISSANCE (CONJOINT)</Text>
              <Text style={styles.field}>{formatDate(adherent.birthDateLine2)}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={{ flex: 1, marginRight: 5 }}>
              <Text style={styles.label}>N° DE SÉCURITÉ SOCIALE</Text>
              <Text style={styles.field}>{adherent.socialSecurityNumber}</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={styles.label}>N° DE SÉCURITÉ SOCIALE (CONJOINT)</Text>
              <Text style={styles.field}>{adherent.socialSecurityNumberLine2}</Text>
            </View>
          </View>

          <Text style={styles.label}>ADRESSE</Text>
          <Text style={styles.field}>{adherent.address}</Text>

          <View style={styles.tableRow}>
            <View style={{ flex: 1, marginRight: 5 }}>
              <Text style={styles.label}>TÉLÉPHONE</Text>
              <Text style={styles.field}>{adherent.phone}</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={styles.label}>ADRESSE MAIL</Text>
              <Text style={styles.field}>{adherent.email}</Text>
            </View>
          </View>

          <Text style={styles.label}>NATIONALITÉ</Text>
          <Text style={styles.field}>{adherent.nationality}</Text>

          <Text style={styles.label}>LIEU D'INHUMATION</Text>
          <Text style={styles.field}>{adherent.burialSite}</Text>

          <Text style={styles.label}>STATUT DE COTISATION</Text>
          <Text style={styles.field}>{adherent.contributionStatus}</Text>
        </View>

        {/* Family Status */}
        <FamilyStatusTable maritalStatus={adherent.maritalStatus} />

        {/* Children Information */}
        <View style={styles.childrenTable}>
          <Text style={[styles.label, { marginBottom: 5 }]}>NOM DES ENFANTS ET DATE DE NAISSANCE</Text>
          {[...Array(5)].map((_, index) => (
            <View key={index} style={styles.childRow}>
              <View style={styles.childNameCell}>
                <Text style={styles.childTableText}>{children[index]?.name || ''}</Text>
              </View>
              <View style={styles.childDateCell}>
                <Text style={styles.childTableText}>{children[index]?.birthDate ? formatDate(children[index].birthDate) : ''}</Text>
              </View>
            </View>
          ))}
        </View>

        {/* Commitments */}
        <View style={{ marginTop: 8 }}>
          <Text style={[styles.smallerText, { lineHeight: 1.2 }]}>
            Je m'engage à respecter le règlement intérieur de l'association - 
            Cotisation annuelle à acquitter du 1 janvier au 28 février - 
            Un enfant ayant eu 25 ans dans l'année en cours: 25€ - 
            Enfant se mariant avant 25 ans: 50€/an - 
            Enfant ayant 25 ans avant le 30 Septembre doit cotiser
          </Text>
        </View>

        {/* Documents à fournir */}
        <DocumentsList />

        {/* RGPD Consent avec espace réduit */}
        <View style={{ marginTop: 5, flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ marginRight: 10 }}>
            <CheckBox checked={false} />
          </View>
          <View style={{ flex: 1 }}>
            <Text style={[styles.label, { fontSize: baseFontSize - 1 }]}>
              Je consens à ce que l'association El Mektoub collecte et utilise mes données personnelles renseignées dans ce formulaire. 
              Ces informations sont nécessaires pour la gestion administrative de l'association et le suivi des adhérents. 
              Conformément au RGPD, je dispose d'un droit d'accès, de rectification et de suppression de mes données 
              que je peux exercer en contactant l'association.
            </Text>
          </View>
        </View>

        {/* Contact Information */}
        <View style={{ marginTop: 5, alignItems: 'center' }}>
          <Text style={[styles.label, { fontSize: baseFontSize - 1 }]}>
            Pour nous contacter : contact@associationelmektoub.org
          </Text>
        </View>

        {/* Signature section */}
        <View style={{ marginTop: 10, alignItems: 'flex-end' }}>
          <Text style={styles.label}>FAIT À.........................LE..........................</Text>
          <View style={styles.signatureContainer}>
            <View>
              <Text style={styles.signatureLabel}>Signature</Text>
              <View style={styles.signature}></View>
            </View>
          </View>
        </View>

        {/* Contribution Years */}
        <CheckBoxTable lastUpdatedYear={lastUpdatedYear} />
      </Page>
    </Document>
  );
};

export default AdherentPDF;
