import React from "react";
// Firebase auth functions
import {
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
} from "firebase/auth";
// Firebase auth instance
import firebaseAuth from "lib/firebase";
import { createUserDocument } from "lib/firebase";
import { db } from "lib/firebase";
import { addDoc, collection, getDocs, query, where, doc, updateDoc, getDoc, deleteDoc } from "firebase/firestore";

// Google oauth provider
const provider = new GoogleAuthProvider();
// Contexts
export const AuthContext = React.createContext(null);

export const ContextProvider = (props) => {
  // States to check auth status
  const [isSignedIn, setIsSignedIn] = React.useState(false);
  const [user, setUser] = React.useState(null);
  React.useEffect(() => {
    // Listener updates auth status when detects change
    onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        setIsSignedIn(true);
        getUserData(user);
        // setUser(user);
      } else {
        setIsSignedIn(false);
        setUser(null);
      }
    });
  }, []);

  


  // Nouvelle fonction pour synchroniser avec PostgreSQL
  const synchronizeUser = async (firebaseUser) => {
    try {
      // Envoie une requête à votre backend avec l'UID Firebase de l'utilisateur
      // Votre backend va vérifier si cet utilisateur existe dans PostgreSQL
      // et l'ajouter si nécessaire
      const response = await fetch('https://app.falconmarketing.fr/synchronize-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: firebaseUser.uid, email: firebaseUser.email }),
      });
      const data = await response.json();
      console.log('User synchronized with PostgreSQL:', data);
    } catch (error) {
      console.error('Error synchronizing user:', error);
    }
  };

  // Modification des fonctions signIn et signUp
  const signIn = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(firebaseAuth, email, password);
      await synchronizeUser(userCredential.user); // Synchronise après la connexion
      return;
    } catch (err) {
      console.log(err.message);
      return err.message;
    }
  };

  const signUp = async (email, password, salesCode) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(firebaseAuth, email, password);
      await createUserDocument(userCredential.user, { salesCode });
      await synchronizeUser(userCredential.user); // Synchronise après l'inscription
      return;
    } catch (err) {
      console.log(err.message);
      return err.message;
    }
  };

  const signOut = async () => {
    try {
      await firebaseSignOut(firebaseAuth);
    } catch (err) {
      console.log(err.message);
    }
  };
  const googleSignIn = async () => {
    try {
      await signInWithPopup(firebaseAuth, provider);
    } catch (err) {
      console.log(err.message);
    }
  };

  const createUserDocument = async (user, additionalData) => {
    const { email } = user;
    const { salesCode } = additionalData;

    try {
        await addDoc(collection(db, "users"), {
            salesCode,
            email,
            nom: user.displayName?.split(' ')[1] || '', // Nom de famille
            prenom: user.displayName?.split(' ')[0] || '', // Prénom
            createdAt: new Date(),
        });
    } catch (error) {
        console.log("error", error);
    }
  };

  const getUserData = async (user) => {
    try {
        const q = query(collection(db, "users"), where("email", "==", user.email));
        const querySnapshot = await getDocs(q);
        
        if (querySnapshot.empty) {
            // Si l'utilisateur n'existe pas encore dans la collection users, créez-le
            const userDoc = {
                uid: user.uid,  // Ajout explicite de l'uid
                email: user.email,
                nom: user.displayName?.split(' ')[1] || '',
                prenom: user.displayName?.split(' ')[0] || '',
                createdAt: new Date(),
            };
            
            const docRef = await addDoc(collection(db, "users"), userDoc);
            
            // Mettre à jour le document avec son ID
            await updateDoc(docRef, { docId: docRef.id });
            
            console.log("Nouvel utilisateur créé:", userDoc);
            setUser({ 
                ...user, 
                profileData: {
                    ...userDoc,
                    id: docRef.id,
                    docId: docRef.id
                }, 
                isAdmin: false 
            });
        } else {
            const doc = querySnapshot.docs[0];
            const userData = doc.data();
            
            // Mettre à jour l'uid si nécessaire
            if (!userData.uid || userData.uid !== user.uid) {
                await updateDoc(doc.ref, {
                    uid: user.uid,
                    docId: doc.id
                });
                userData.uid = user.uid;
                userData.docId = doc.id;
            }
            
            console.log("Données utilisateur récupérées:", userData);
            
            // Vérifier si les données sont complètes
            if (!userData.nom || !userData.prenom) {
                const updatedData = {
                    ...userData,
                    nom: userData.nom || user.displayName?.split(' ')[1] || '',
                    prenom: userData.prenom || user.displayName?.split(' ')[0] || '',
                    uid: user.uid,  // S'assurer que l'uid est présent
                    docId: doc.id
                };
                
                await updateDoc(doc.ref, updatedData);
                console.log("Données utilisateur mises à jour:", updatedData);
                
                setUser({
                    ...user,
                    profileData: {
                        ...updatedData,
                        id: doc.id,
                        docId: doc.id
                    },
                    isAdmin: userData.admin || false
                });
            } else {
                setUser({
                    ...user,
                    profileData: {
                        ...userData,
                        id: doc.id,
                        docId: doc.id
                    },
                    isAdmin: userData.admin || false
                });
            }
        }
    } catch (error) {
        console.error("Erreur lors de la récupération des données utilisateur:", error);
        // En cas d'erreur, on définit quand même les données de base
        setUser({
            ...user,
            profileData: {
                email: user.email,
                nom: user.displayName?.split(' ')[1] || '',
                prenom: user.displayName?.split(' ')[0] || '',
                uid: user.uid
            },
            isAdmin: false
        });
    }
  };

  const saveMemberToFirestore = async (memberData) => {
    try {
      await addDoc(collection(db, "members"), {
        ...memberData,
        createdAt: new Date(),
      });
      console.log("Membre ajouté avec succès");
    } catch (error) {
      console.log("Erreur lors de l'ajout du membre", error);
    }
  };

  const updateMemberInFirestore = async (memberId, memberData) => {
    // Assurez-vous que memberId est non nul et non vide
    if (!memberId) {
      console.error("Aucun ID de membre fourni pour la mise à jour.");
      return; // Sortie anticipée de la fonction si l'ID est invalide
    }
  
    const memberRef = doc(db, "members", memberId);
  
    // Vérification de l'existence du membre avant de tenter de le mettre à jour
    const docSnap = await getDoc(memberRef);
    if (!docSnap.exists()) {
      console.error("Le membre à mettre à jour n'existe pas.");
      // Vous pouvez ici gérer le cas où le membre n'existe pas, par exemple en informant l'utilisateur
      return; // Sortie anticipée de la fonction si le document n'existe pas
    }
  
    // Si le membre existe, procédez à la mise à jour
    try {
      await updateDoc(memberRef, memberData);
      console.log("Membre mis à jour avec succès.");
      // Ici, vous pouvez notifier l'utilisateur du succès de la mise à jour, par exemple via un toast
    } catch (error) {
      console.error("Erreur lors de la mise à jour du membre :", error);
      // Ici, vous pouvez gérer l'erreur, par exemple en informant l'utilisateur via un toast
    }
  };

  // Définissez une fonction pour supprimer un membre
const deleteMember = async (memberId) => {
  try {
    // Construisez la référence au document Firestore
    const memberRef = doc(db, "members", memberId);
    // Supprimez le document correspondant
    await deleteDoc(memberRef);
    console.log("Document successfully deleted!");
  } catch (error) {
    console.error("Error deleting document: ", error);
  }
};

  
  
  // Context provider
  return (
    <AuthContext.Provider
      value={{
        isSignedIn,
        user,
        signIn,
        signUp,
        signOut,
        googleSignIn,
        getUserData,
        saveMemberToFirestore,
        updateMemberInFirestore,
        deleteMember,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};