import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  HStack,
  IconButton,
  Box,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Flex,
  Text,
  Icon,
  useColorModeValue,
  useBreakpointValue,
  SimpleGrid,
  chakra,
  FormErrorMessage,
  Image,
} from '@chakra-ui/react';
import { MdAdd, MdDelete, MdPersonAdd, MdUpload, MdDownload } from 'react-icons/md';
import { ViewIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'antd/dist/reset.css';

// Configurez dayjs pour utiliser le français
dayjs.locale('fr');

// Composants réutilisables
const FormSection = ({ title, children }) => {
  const sectionBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.100", "gray.700");
  const titleColor = useColorModeValue("gray.700", "white");

  return (
    <Box 
      bg={sectionBg}
      borderRadius="xl"
      borderWidth="1px"
      borderColor={borderColor}
      overflow="hidden"
    >
      <Box 
        p={4} 
        borderBottomWidth="1px" 
        borderColor={borderColor}
        bg={useColorModeValue("gray.50", "gray.900")}
      >
        <Text 
          fontSize="md"
          fontWeight="semibold"
          color={titleColor}
          textTransform="uppercase"
          letterSpacing="wide"
        >
          {title}
        </Text>
      </Box>
      <Box p={6}>
        {children}
      </Box>
    </Box>
  );
};

const StyledInput = ({ label, error, value, onChange, name, ...props }) => {
  const inputRef = useRef(null);
  const inputBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{label}</FormLabel>
      <Input
        ref={inputRef}
        bg={inputBg}
        borderColor={borderColor}
        value={value}
        onChange={onChange}
        name={name}
        _hover={{
          borderColor: borderColor,
          transform: "translateY(-1px)",
          boxShadow: "sm"
        }}
        transition="all 0.2s"
        {...props}
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

const StyledSelect = ({ label, error, children, ...props }) => {
  const selectRef = useRef(null);
  const selectBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const handleFocus = () => {
    if (selectRef.current) {
      const modalBody = document.querySelector('.chakra-modal__body');
      if (modalBody) {
        const selectRect = selectRef.current.getBoundingClientRect();
        const modalRect = modalBody.getBoundingClientRect();
        const scrollTarget = modalBody.scrollTop + (selectRect.top - modalRect.top) - (modalRect.height / 2) + (selectRect.height / 2);
        
        modalBody.scrollTo({
          top: scrollTarget,
          behavior: 'smooth'
        });
      }
    }
  };

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{label}</FormLabel>
      <Select
        ref={selectRef}
        onFocus={handleFocus}
        bg={selectBg}
        borderColor={borderColor}
        _hover={{
          borderColor: borderColor,
          transform: "translateY(-1px)",
          boxShadow: "sm"
        }}
        transition="all 0.2s"
        {...props}
      >
        {children}
      </Select>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

const StyledDateInput = ({ label, value, onChange, error, ...props }) => {
  const inputRef = useRef(null);
  const inputBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    // Met à jour l'affichage quand la valeur externe change
    if (value) {
      try {
        // Essaie de parser la date dans différents formats
        let date;
        if (typeof value === 'string' && value.includes('T')) {
          // Format ISO
          date = new Date(value);
        } else if (typeof value === 'string' && value.includes('-')) {
          // Format YYYY-MM-DD
          const [year, month, day] = value.split('-');
          date = new Date(year, month - 1, day);
        } else {
          // Autres cas
          date = new Date(value);
        }

        if (!isNaN(date.getTime())) {
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();
          setInputValue(`${day}/${month}/${year}`);
        } else {
          setInputValue('');
        }
      } catch (error) {
        console.error('Erreur de parsing de date:', error);
        setInputValue('');
      }
    } else {
      setInputValue('');
    }
  }, [value]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);

    // Permet seulement les chiffres et les /
    const cleanValue = newValue.replace(/[^\d/]/g, '');
    
    // Ajoute automatiquement les /
    let formattedValue = cleanValue;
    if (cleanValue.length === 2 && inputValue.length === 1) formattedValue += '/';
    if (cleanValue.length === 5 && inputValue.length === 4) formattedValue += '/';
    
    setInputValue(formattedValue);
    
    // Si on a une date complète, on la convertit en format ISO
    if (formattedValue.length === 10) {
      const [day, month, year] = formattedValue.split('/');
      const date = new Date(year, month - 1, day);
      if (!isNaN(date.getTime())) {
        onChange(`${year}-${month}-${day}`);
      }
    }
  };

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{label}</FormLabel>
      <Input
        ref={inputRef}
        maxLength={10}
        placeholder="JJ/MM/AAAA"
        value={inputValue}
        onChange={handleChange}
        bg={inputBg}
        borderColor={borderColor}
        _hover={{
          borderColor: borderColor,
          transform: "translateY(-1px)",
          boxShadow: "sm"
        }}
        transition="all 0.2s"
        {...props}
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

const FilePreviewModal = ({ isOpen, onClose, fileUrl, fileName }) => {
  const [localUrl, setLocalUrl] = useState('');
  const fileType = fileName.split('.').pop().toLowerCase();
  const isPDF = fileType === 'pdf';
  const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileType);

  useEffect(() => {
    const fetchAndCreateLocalUrl = async () => {
      try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const localBlobUrl = URL.createObjectURL(blob);
        setLocalUrl(localBlobUrl);
      } catch (error) {
        console.error('Erreur lors du chargement du fichier:', error);
      }
    };

    if (isOpen && (isPDF || isImage)) {
      fetchAndCreateLocalUrl();
    }

    return () => {
      if (localUrl) {
        URL.revokeObjectURL(localUrl);
        setLocalUrl('');
      }
    };
  }, [isOpen, fileUrl]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent maxH="90vh">
        <ModalHeader>{fileName}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isPDF && localUrl && (
            <iframe
              src={localUrl}
              style={{ width: '100%', height: '80vh' }}
              title={fileName}
            />
          )}
          {isImage && localUrl && (
            <Image
              src={localUrl}
              alt={fileName}
              maxH="80vh"
              mx="auto"
              objectFit="contain"
            />
          )}
          {!localUrl && (isPDF || isImage) && (
            <Flex justify="center" align="center" h="200px">
              <Text>Chargement en cours...</Text>
            </Flex>
          )}
          {!isPDF && !isImage && (
            <VStack spacing={4} py={8}>
              <Text>Ce type de fichier ne peut pas être prévisualisé directement.</Text>
              <Button
                colorScheme="blue"
                leftIcon={<MdDownload />}
                onClick={() => window.open(fileUrl, '_blank')}
              >
                Télécharger le fichier
              </Button>
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const FileItem = ({ file, onRemove, onDeleteExisting }) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const itemBg = useColorModeValue("gray.50", "gray.700");
  const textColor = useColorModeValue("gray.900", "white");
  const fileName = file.filePath ? file.filePath.split('/').pop() : file.name;
  const fileUrl = file.filePath ? `https://app.falconmarketing.fr:3004/${file.filePath}` : URL.createObjectURL(file);

  return (
    <>
      <Flex
        p={3}
        bg={itemBg}
        borderRadius="lg"
        align="center"
        justify="space-between"
        transition="all 0.2s"
        _hover={{ transform: "translateY(-2px)" }}
      >
        <HStack flex={1} spacing={3}>
          <Icon as={ViewIcon} boxSize={4} color="blue.500" />
          <Text fontSize="sm" fontWeight="medium" color={textColor} noOfLines={1}>
            {fileName}
          </Text>
        </HStack>
        <HStack spacing={2}>
          <IconButton
            aria-label="Voir le fichier"
            icon={<ViewIcon />}
            colorScheme="blue"
            variant="ghost"
            size="sm"
            onClick={() => setIsPreviewOpen(true)}
          />
          <IconButton
            aria-label="Supprimer le fichier"
            icon={<MdDelete />}
            colorScheme="red"
            variant="ghost"
            size="sm"
            onClick={() => file.filePath ? onDeleteExisting(file.id) : onRemove(file)}
          />
        </HStack>
      </Flex>
      <FilePreviewModal
        isOpen={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
        fileUrl={fileUrl}
        fileName={fileName}
      />
    </>
  );
};

const FileSection = ({ files, selectedFiles, onFileChange, onRemoveFile, onDeleteExistingFile, fileInputRef }) => {
  const bgColor = useColorModeValue("gray.50", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const fileBg = useColorModeValue("white", "gray.800");

  return (
    <FormSection title="Pièces justificatives">
      <VStack spacing={4} align="stretch">
        <HStack>
          <Button
            leftIcon={<MdUpload />}
            onClick={() => fileInputRef.current?.click()}
            colorScheme="blue"
            variant="outline"
            size="sm"
          >
            Ajouter des fichiers
          </Button>
          <input
            type="file"
            multiple
            onChange={onFileChange}
            style={{ display: 'none' }}
            ref={fileInputRef}
            accept="application/pdf,image/*"
          />
        </HStack>

        {selectedFiles.length > 0 && (
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={3}>
            {selectedFiles.map((file, index) => (
              <FileItem
                key={index}
                file={file}
                onRemove={() => onRemoveFile(index)}
                onDeleteExisting={onDeleteExistingFile}
              />
            ))}
          </SimpleGrid>
        )}

        {files.length > 0 && (
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={3}>
            {files.map((file) => (
              <FileItem
                key={file.id}
                file={file}
                onRemove={onRemoveFile}
                onDeleteExisting={onDeleteExistingFile}
              />
            ))}
          </SimpleGrid>
        )}
      </VStack>
    </FormSection>
  );
};

const CustomModalBody = chakra(ModalBody, {
  baseStyle: {
    "&::-webkit-scrollbar": {
      width: "12px",
      borderRadius: "10px",
      backgroundColor: `rgba(0, 0, 0, 0.05)`,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: `rgba(0, 0, 0, 0.1)`,
      minHeight: "50px",
      border: "3px solid transparent",
      backgroundClip: "content-box",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: `rgba(0, 0, 0, 0.2)`,
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "transparent",
    },
    scrollbarWidth: "thin",
    scrollbarColor: `rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.05)`,
  },
});

const EditMemberModal = ({ isOpen, onClose, adherent, onSave, onDelete }) => {
  const initialMemberState = {
    mrName: '',
    mrsName: '',
    socialSecurityNumber: '',
    socialSecurityNumberLine2: '',
    birthDate: '',
    birthDateLine2: '',
    address: '',
    phone: '',
    email: '',
    nationality: '',
    burialSite: '',
    maritalStatus: '',
    contributionStatus: '',
    delegateName: '',
  };

  const history = useHistory();
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [member, setMember] = useState(initialMemberState);
  const [children, setChildren] = useState([{ name: '', birthDate: '' }]);
  const [childrenToDelete, setChildrenToDelete] = useState([]);
  const [files, setFiles] = useState([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [delegates, setDelegates] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [childToTransfer, setChildToTransfer] = useState(null);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  
  const fileInputRef = useRef(null);
  const cancelRef = useRef();
  const childBoxBg = useColorModeValue("white", "gray.800");
  const childBoxBorderColor = useColorModeValue("gray.200", "gray.600");

  // Initialisation des données lors de l'ouverture de la modale
  useEffect(() => {
    if (isOpen) {
      // Initialiser les enfants
      const fetchChildren = async () => {
        if (adherent?.id) {
          try {
            const response = await fetch(`https://app.falconmarketing.fr:3004/adherents/${adherent.id}/children`);
            if (response.ok) {
              const data = await response.json();
              setChildren(data.children?.length > 0 ? data.children : [{ name: '', birthDate: '' }]);
            }
          } catch (error) {
            console.error('Erreur lors de la récupération des enfants:', error);
            setChildren([{ name: '', birthDate: '' }]);
          }
        }
      };

      // Initialiser les délégués
      const fetchDelegates = async () => {
        try {
          const response = await fetch('https://app.falconmarketing.fr:3004/delegates');
          if (response.ok) {
            const data = await response.json();
            setDelegates(data.delegateNames || []);
          }
        } catch (error) {
          console.error('Erreur lors de la récupération des délégués:', error);
        }
      };

      // Initialiser le membre
      if (adherent) {
        const memberData = {
          ...initialMemberState,
          ...Object.fromEntries(
            Object.entries(adherent).filter(([key, value]) => 
              key !== 'children' && value != null
            )
          )
        };
        setMember(memberData);
      } else {
        setMember(initialMemberState);
      }

      fetchChildren();
      fetchDelegates();
    }
  }, [isOpen, adherent?.id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (!name) return;

    if (name.startsWith('children.')) {
      const [_, field, index] = name.split('.');
      const childIndex = parseInt(index, 10);
      if (!isNaN(childIndex) && childIndex >= 0 && childIndex < children.length) {
        setChildren(prev => {
          const updated = [...prev];
          updated[childIndex] = {
            ...updated[childIndex],
            [field]: value
          };
          return updated;
        });
      }
    } else {
      setMember(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleCreateDeathRecord = (name) => {
    try {
      if (name) {
        history.push('/admin/deces', { name });
      } else {
        toast({
          title: 'Erreur',
          description: 'Veuillez d\'abord saisir un nom',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Erreur lors de la création du dossier de décès:', error);
    }
  };

  const handleFileChange = (e) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const newFiles = Array.from(e.target.files);
        setSelectedFiles(prevFiles => [...prevFiles, ...newFiles]);
        e.target.value = '';
      }
    } catch (error) {
      console.error('Erreur lors du changement de fichier:', error);
    }
  };

  const handleClose = () => {
    try {
      setIsAlertOpen(false);
      onClose();
    } catch (error) {
      console.error('Erreur lors de la fermeture:', error);
    }
  };

  const fetchMembers = async () => {
    try {
      const response = await fetch('https://app.falconmarketing.fr:3004/adherents');
      if (response.ok) {
        const data = await response.json();
        // Gérer les données
      } else {
        console.error('Échec de la récupération des adhérents:', response.statusText);
      }
    } catch (error) {
      console.error('Échec de la récupération des adhérents:', error);
      // Ne pas propager l'erreur pour éviter la fermeture de la modale
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setIsAlertOpen(false);
    }
  }, [isOpen]);

  const handleAddChild = () => {
    try {
      setChildren(prev => [...prev, { name: '', birthDate: '' }]);
    } catch (error) {
      console.error('Erreur lors de l\'ajout d\'un enfant:', error);
    }
  };

  const handleRemoveChild = (index) => {
    try {
      if (index >= 0 && index < children.length) {
        const child = children[index];
        if (child && child.id) {
          setChildrenToDelete(prev => [...prev, child.id]);
        }
        const updatedChildren = [...children];
        updatedChildren.splice(index, 1);
        setChildren(updatedChildren);
      }
    } catch (error) {
      console.error('Erreur lors de la suppression d\'un enfant:', error);
    }
  };

  const handleSave = async () => {
    try {
      // Vérifier que l'adhérent existe
      if (!adherent || !adherent.id) {
        throw new Error('Adhérent non valide');
      }

      // Suppression des enfants
      await Promise.all(childrenToDelete.map(async childId => {
        try {
          const response = await fetch(`https://app.falconmarketing.fr:3004/children/${childId}`, {
            method: 'DELETE',
          });
          if (!response.ok) {
            console.error(`Échec de la suppression de l'enfant ${childId}:`, response.statusText);
          }
        } catch (error) {
          console.error(`Échec de la suppression de l'enfant ${childId}:`, error);
        }
      }));
      
      setChildrenToDelete([]);

      // Mise à jour des informations de l'adhérent
      const adherentResponse = await fetch(`https://app.falconmarketing.fr:3004/adherents/${adherent.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(member),
      });

      if (!adherentResponse.ok) {
        throw new Error('Échec de la mise à jour de l\'adhérent');
      }

      // Téléchargement des nouveaux fichiers
      if (selectedFiles.length > 0) {
        const formData = new FormData();
        selectedFiles.forEach(file => {
          formData.append('files', file);
        });

        const fileResponse = await fetch(`https://app.falconmarketing.fr:3004/adherents/${adherent.id}/files`, {
          method: 'POST',
          body: formData,
        });

        if (!fileResponse.ok) {
          throw new Error('Échec du téléchargement des fichiers');
        }
      }

      // Mise à jour des enfants
      await Promise.all(children.map(async (child) => {
        if (!child.name) return; // Ignorer les enfants sans nom
        
        const url = child.id 
          ? `https://app.falconmarketing.fr:3004/children/${child.id}` 
          : `https://app.falconmarketing.fr:3004/children/${adherent.id}`;
        const method = child.id ? 'PUT' : 'POST';

        const childResponse = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: child.name,
            birthDate: child.birthDate || '',
            adherentId: adherent.id,
          }),
        });

        if (!childResponse.ok) {
          console.error('Échec de la mise à jour ou de l\'ajout de l\'enfant:', childResponse.statusText);
        }
      }));

      toast({
        title: 'Succès',
        description: 'Informations de l\'adhérent mises à jour avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setSelectedFiles([]);
      
      handleClose();
    } catch (error) {
      toast({
        title: 'Erreur',
        description: `Une erreur est survenue : ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteAdherent = async () => {
    try {
      if (!adherent || !adherent.id) {
        throw new Error('Adhérent non valide');
      }
      
      const response = await fetch(`https://app.falconmarketing.fr:3004/adherents/${adherent.id}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Échec de la suppression de l\'adhérent');
      }
      toast({
        title: 'Adhérent supprimé',
        description: 'L\'adhérent et ses enfants ont été supprimés avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setIsDeleteAlertOpen(false);
      if (typeof onDelete === 'function') {
        onDelete(adherent.id);
      }
      handleClose();
    } catch (error) {
      toast({
        title: 'Erreur',
        description: `Une erreur est survenue : ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsDeleteAlertOpen(false);
    }
  };

  const handleCreateAdherentFromChild = async (childId) => {
    try {
      if (!childId) {
        throw new Error('ID enfant non valide');
      }
      
      const response = await fetch(`https://app.falconmarketing.fr:3004/children/${childId}/transfer`, {
        method: 'POST',
      });
  
      if (response.ok) {
        toast({
          title: 'Succès',
          description: 'L\'enfant a été transféré en tant qu\'adhérent avec succès.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        fetchMembers();
      } else {
        toast({
          title: 'Erreur',
          description: 'Une erreur est survenue lors du transfert de l\'enfant en adhérent.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Erreur',
        description: `Une erreur est survenue : ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRemoveSelectedFile = (index) => {
    try {
      if (index >= 0 && index < selectedFiles.length) {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
      }
    } catch (error) {
      console.error('Erreur lors de la suppression du fichier sélectionné:', error);
    }
  };

  const handleDeleteExistingFile = async (fileId) => {
    try {
      if (!fileId || !adherent || !adherent.id) {
        throw new Error('Paramètres non valides');
      }
      
      const response = await fetch(`https://app.falconmarketing.fr:3004/adherents/${adherent.id}/files/${fileId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error("Erreur lors de la suppression du fichier");
      }

      const updatedFiles = files.filter(file => file.id !== fileId);
      setFiles(updatedFiles);

      toast({
        title: 'Succès',
        description: 'Le fichier a été supprimé avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erreur',
        description: `Impossible de supprimer le fichier: ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const fetchFiles = async () => {
      if (adherent && adherent.id) {
        try {
          const response = await fetch(`https://app.falconmarketing.fr:3004/adherents/${adherent.id}/files`);
          if (!response.ok) {
            throw new Error('Erreur lors du chargement des fichiers');
          }
          const data = await response.json();
          setFiles(data.files || []);
        } catch (error) {
          console.error('Erreur :', error);
          toast({
            title: 'Erreur',
            description: 'Impossible de charger les fichiers',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          // Initialiser avec un tableau vide pour éviter les erreurs
          setFiles([]);
        }
      }
    };

    if (isOpen && adherent && adherent.id) {
      fetchFiles();
    }
  }, [isOpen, adherent, toast]);

  const handleTransferConfirmation = (child) => {
    try {
      if (child) {
        setChildToTransfer(child);
        setIsTransferModalOpen(true);
      }
    } catch (error) {
      console.error('Erreur lors de la confirmation de transfert:', error);
    }
  };

  const handleTransferCancel = () => {
    try {
      setChildToTransfer(null);
      setIsTransferModalOpen(false);
    } catch (error) {
      console.error('Erreur lors de l\'annulation du transfert:', error);
    }
  };

  const handleTransferConfirm = async () => {
    try {
      if (childToTransfer && childToTransfer.id) {
        await handleCreateAdherentFromChild(childToTransfer.id);
        setIsTransferModalOpen(false);
        setChildToTransfer(null);
      }
    } catch (error) {
      console.error('Erreur lors de la confirmation du transfert:', error);
      setIsTransferModalOpen(false);
      setChildToTransfer(null);
    }
  };

  const handleDateChange = (value, name) => {
    if (!name) return;
    
    setMember(prev => ({
      ...prev,
      [name]: value || ''
    }));
  };

  const handleChildDateChange = (value, index) => {
    if (index < 0 || index >= children.length) return;

    const updatedChildren = [...children];
    updatedChildren[index] = {
      ...updatedChildren[index],
      birthDate: value || ''
    };
    setChildren(updatedChildren);
  };

  // Prévenir la fermeture accidentelle de la modale
  const preventClose = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <Modal 
        isOpen={isOpen} 
        onClose={handleClose} 
        size={isMobile ? "full" : "6xl"}
        motionPreset={isMobile ? "slideInBottom" : "scale"}
        closeOnOverlayClick={false}
      >
        <ModalOverlay backdropFilter="blur(5px)" bg="blackAlpha.300" onClick={preventClose} />
        <ModalContent 
          margin={isMobile ? 0 : "3.75rem auto"}
          bg={useColorModeValue("gray.50", "gray.900")}
          borderRadius={isMobile ? "none" : "2xl"}
          overflow="hidden"
        >
          <ModalHeader
            bg={useColorModeValue("white", "gray.800")}
            borderBottom="1px"
            borderColor={useColorModeValue("gray.100", "gray.700")}
            p={6}
          >
            <Text
              fontSize={isMobile ? "xl" : "2xl"}
              fontWeight="bold"
              color={useColorModeValue("gray.900", "white")}
            >
              Modifier les informations du membre
            </Text>
          </ModalHeader>

          <ModalCloseButton 
            size={isMobile ? "lg" : "md"}
            top={6}
            right={6}
            onClick={handleClose}
          />

          <CustomModalBody 
            p={6}
            maxH="calc(100vh - 200px)"
            overflowY="auto"
            css={{
              scrollBehavior: "smooth",
              "&::-webkit-scrollbar": {
                width: "12px",
                borderRadius: "10px",
                backgroundColor: useColorModeValue("rgba(0, 0, 0, 0.05)", "rgba(255, 255, 255, 0.05)"),
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                backgroundColor: useColorModeValue("rgba(0, 0, 0, 0.1)", "rgba(255, 255, 255, 0.1)"),
                minHeight: "50px",
                border: "3px solid transparent",
                backgroundClip: "content-box",
                transition: "all 0.2s ease-in-out",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: useColorModeValue("rgba(0, 0, 0, 0.2)", "rgba(255, 255, 255, 0.2)"),
              },
              "&::-webkit-scrollbar-track": {
                borderRadius: "10px",
                backgroundColor: "transparent",
              },
              scrollbarWidth: "thin",
              scrollbarColor: useColorModeValue(
                "rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.05)",
                "rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.05)"
              ),
            }}
          >
            <VStack spacing={6} align="stretch">
              <FormSection title="Informations Principales">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                  <Box>
                    <HStack>
                      <StyledInput
                        label="Nom et Prénom (Adhérent)"
                        name="mrName"
                        value={member?.mrName || ''}
                        onChange={handleChange}
                        placeholder="Nom et prénom"
                      />
                      <Button 
                        colorScheme="red" 
                        onClick={() => handleCreateDeathRecord(member.mrName)}
                        size="sm"
                        alignSelf="flex-end"
                        mb="2px"
                      >
                        Décès
                      </Button>
                    </HStack>
                  </Box>
                  <Box>
                    <HStack>
                      <StyledInput
                        label="Nom et Prénom (Conjoint)"
                        name="mrsName"
                        value={member.mrsName || ''}
                        onChange={handleChange}
                        placeholder="Nom et prénom"
                      />
                      <Button 
                        colorScheme="red" 
                        onClick={() => handleCreateDeathRecord(member.mrsName)}
                        size="sm"
                        alignSelf="flex-end"
                        mb="2px"
                      >
                        Décès
                      </Button>
                    </HStack>
                  </Box>
                </SimpleGrid>
              </FormSection>

              <FormSection title="Sécurité Sociale">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                  <StyledInput
                    label="N° Sécurité Sociale (Adhérent)"
                    name="socialSecurityNumber"
                    value={member.socialSecurityNumber}
                    onChange={handleChange}
                    placeholder="Numéro de sécurité sociale"
                  />
                  <StyledInput
                    label="N° Sécurité Sociale (Conjoint)"
                    name="socialSecurityNumberLine2"
                    value={member.socialSecurityNumberLine2}
                    onChange={handleChange}
                    placeholder="Numéro de sécurité sociale"
                  />
                </SimpleGrid>
              </FormSection>

              <FormSection title="Dates de Naissance">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                  <StyledDateInput
                    label="Date de naissance"
                    name="birthDate"
                    value={member.birthDate || ''}
                    onChange={(date) => handleDateChange(date, 'birthDate')}
                  />
                  <StyledDateInput
                    label="Date de naissance (conjoint)"
                    name="birthDateLine2"
                    value={member.birthDateLine2 || ''}
                    onChange={(date) => handleDateChange(date, 'birthDateLine2')}
                  />
                </SimpleGrid>
              </FormSection>

              <FormSection title="Coordonnées">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                  <StyledInput
                    label="Adresse"
                    name="address"
                    value={member.address}
                    onChange={handleChange}
                    placeholder="Adresse complète"
                  />
                  <StyledInput
                    label="Téléphone"
                    name="phone"
                    value={member.phone}
                    onChange={handleChange}
                    placeholder="Numéro de téléphone"
                  />
                  <StyledInput
                    label="Email"
                    name="email"
                    value={member.email}
                    onChange={handleChange}
                    placeholder="Adresse email"
                  />
                </SimpleGrid>
              </FormSection>

              <FormSection title="Informations Complémentaires">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                  <StyledInput
                    label="Nationalité"
                    name="nationality"
                    value={member.nationality}
                    onChange={handleChange}
                    placeholder="Nationalité"
                  />
                  <StyledInput
                    label="Lieu d'inhumation"
                    name="burialSite"
                    value={member.burialSite}
                    onChange={handleChange}
                    placeholder="Lieu d'inhumation"
                  />
                  <StyledSelect
                    label="Situation familiale"
                    name="maritalStatus"
                    value={member.maritalStatus}
                    onChange={handleChange}
                    placeholder="Sélectionnez la situation familiale"
                  >
                    <option value="married">Marié</option>
                    <option value="single">Célibataire</option>
                    <option value="divorcedWithChildren">Divorcé avec enfants</option>
                    <option value="divorcedWithoutChildren">Divorcé sans enfants</option>
                    <option value="widowed">Veuf/Veuve</option>
                  </StyledSelect>
                </SimpleGrid>
              </FormSection>

              <FormSection title="Délégué">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                  <StyledSelect
                    label="Nom du délégué"
                    name="delegateName"
                    value={member.delegateName}
                    onChange={handleChange}
                    placeholder="Sélectionnez un délégué"
                  >
                    {delegates.map((delegate, index) => (
                      <option key={index} value={delegate}>
                        {delegate}
                      </option>
                    ))}
                  </StyledSelect>
                </SimpleGrid>
              </FormSection>

              <FormSection title="Enfants">
                <VStack spacing={6} align="stretch">
                  {children.map((child, index) => (
                    <Box
                      key={index}
                      p={4}
                      bg={childBoxBg}
                      borderRadius="md"
                      borderWidth="1px"
                      borderColor={childBoxBorderColor}
                      position="relative"
                    >
                      <VStack spacing={4} align="stretch">
                        <StyledInput
                          label="Nom et Prénom"
                          name={`children.name.${index}`}
                          placeholder="Nom et prénom de l'enfant"
                          value={child.name}
                          onChange={(e) => {
                            const updatedChildren = [...children];
                            updatedChildren[index] = {
                              ...updatedChildren[index],
                              name: e.target.value
                            };
                            setChildren(updatedChildren);
                          }}
                        />
                        <StyledDateInput
                          label="Date de naissance"
                          value={child.birthDate}
                          onChange={(date) => {
                            const updatedChildren = [...children];
                            updatedChildren[index] = {
                              ...updatedChildren[index],
                              birthDate: date
                            };
                            setChildren(updatedChildren);
                          }}
                        />
                      </VStack>
                      <IconButton
                        aria-label="Supprimer l'enfant"
                        icon={<MdDelete />}
                        onClick={() => handleRemoveChild(index)}
                        colorScheme="red"
                        variant="ghost"
                        position="absolute"
                        top={2}
                        right={2}
                      />
                    </Box>
                  ))}
                  <Button
                    leftIcon={<MdAdd />}
                    onClick={handleAddChild}
                    colorScheme="blue"
                    variant="outline"
                    size="sm"
                    alignSelf="flex-start"
                  >
                    Ajouter un enfant
                  </Button>
                </VStack>
              </FormSection>

              <FormSection title="Statut de cotisation">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                  <StyledSelect
                    label="Statut de cotisation"
                    name="contributionStatus"
                    value={member.contributionStatus}
                    onChange={handleChange}
                    placeholder="Sélectionnez le statut de cotisation"
                  >
                    <option value="À jour">À jour</option>
                    <option value="En attente">En attente</option>
                    <option value="En retard N-1">En retard N-1</option>
                    <option value="En retard N-2">En retard N-2</option>
                    <option value="En retard N-3">En retard N-3</option>
                    <option value="En retard +N-3">En retard +N-3</option>
                    <option value="Radié">Radié</option>
                  </StyledSelect>
                </SimpleGrid>
              </FormSection>

              <FileSection
                files={files}
                selectedFiles={selectedFiles}
                onFileChange={handleFileChange}
                onRemoveFile={handleRemoveSelectedFile}
                onDeleteExistingFile={handleDeleteExistingFile}
                fileInputRef={fileInputRef}
              />
            </VStack>
          </CustomModalBody>

          <ModalFooter 
            borderTop="1px" 
            borderColor={useColorModeValue("gray.100", "gray.700")}
            p={4}
            bg={useColorModeValue("white", "gray.800")}
          >
            <HStack spacing={3} width={isMobile ? "full" : "auto"}>
              <Button
                colorScheme="red"
                onClick={() => setIsDeleteAlertOpen(true)}
                size={isMobile ? "lg" : "md"}
                flex={isMobile ? 1 : "auto"}
              >
                Supprimer
              </Button>
              <Button
                colorScheme="blue"
                onClick={handleSave}
                size={isMobile ? "lg" : "md"}
                flex={isMobile ? 1 : "auto"}
              >
                Sauvegarder
              </Button>
              <Button
                onClick={handleClose}
                size={isMobile ? "lg" : "md"}
                flex={isMobile ? 1 : "auto"}
                variant="ghost"
              >
                Annuler
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer l'adhérent
            </AlertDialogHeader>

            <AlertDialogBody>
              Êtes-vous sûr ? Vous ne pouvez pas annuler cette action par la suite.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleteAlertOpen(false)}>
                Annuler
              </Button>
              <Button colorScheme="red" onClick={handleDeleteAdherent} ml={3}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isTransferModalOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleTransferCancel}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Transformer en adhérent
            </AlertDialogHeader>

            <AlertDialogBody>
              Êtes-vous sûr de vouloir transformer {childToTransfer?.name} en adhérent ? 
              Cette action créera un nouveau membre à partir des informations de l'enfant.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={handleTransferCancel}>
                Annuler
              </Button>
              <Button colorScheme="blue" onClick={handleTransferConfirm} ml={3}>
                Confirmer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default EditMemberModal;